import React from "react"
import Image from "gatsby-image"
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
const randomGenerator = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
const NotFoundPage = ({ data }) => (
  <StaticQuery
    query={graphql`
      {
        allFile(filter: { relativeDirectory: { eq: "cats" } }) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { allFile } = data
      const { edges } = allFile
      const randomPosition = randomGenerator(0, edges.length - 1)
      const randomizedImage = edges[randomPosition].node.childImageSharp
      return (
        <Layout>
          <SEO title="404: Not found" />
          <div style={{ backgroundColor: `#ffeb98`, overflow: `auto` }}>
            <h1>Pagina non trovata :(</h1>
            <p>
              Però hai trovato un gatto! (<Link to="/">Tornare alla home?</Link>
              )
            </p>
          </div>
          <Image style={{ maxWidth: `100%` }} fluid={randomizedImage.fluid} />
        </Layout>
      )
    }}
  />
)

export default NotFoundPage
